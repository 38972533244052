<template>
    <div>
        <b-table striped responsive class="mb-0" :items="permissionsData">
            <template #cell(module)="data">
                {{ data.value }}
            </template>
            <template #cell()="data">
                <b-form-checkbox :checked="data.value" />
            </template>
        </b-table>
    </div>
</template>
<script>
import {
  BFormCheckbox, BTable
} from 'bootstrap-vue';
import axios from '@axios';
const {
  axiosRoutes: { permission: path },
} = require("/G_CONFIG");
export default {
    components: {
    BTable,
    BFormCheckbox,
    },
    data() {
        return {
            permissionsData: []
        }
    },
    async created() {
        /* console.log(path.getOne+"/"+this.$route.params.id); */
        await axios.get(path.getOne+"/"+this.$route.params.id).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        });
    },
}
</script>
<style>
    
</style>